
<template>
  <div class="w-full">
    <div class="d-flex justify-center w-full">
      <v-btn
        class="px-6 w-full"
        @click="onUploadClick"
      >
        <v-icon class="pr-4">
          mdi-upload
        </v-icon>
        Bild Hochladen
      </v-btn>
      <input
        ref="FileInput"
        type="file"
        :accept="accept"
        style="display: none;"
        @change="onFileSelect"
      >
    </div>
    <v-dialog
      v-if="dialogWidth"
      v-model="dialog"
      :width="dialogWidth"
    >
      <v-card class="pt-4">
        <v-card-text>
          <VueCropper
            v-show="file"
            ref="cropper"
            :src="file"
            :aspect-ratio="ratio"
            :cropmove="onCropmove"
            :auto-crop-area="0.9"
            :zoomable="false"
            alt="Ausgewähltes Bild"
            @ready="onCropmove"
          />
        </v-card-text>

        <div class="px-6">
          <v-alert
            v-if="isErrorMessageVisible"
            type="error"
          >
            <span v-if="isImageTooSmall">Das Bild muss mindestens {{ minWidth }}px x {{ minHeight }}px sein!<br></span>
            <span v-if="isImageTooBig">Das Bild darf maximal {{ maxWidth }}px x {{ maxHeight }}px sein!<br></span>
            <span v-if="isImageFileTooBig">Das Bild darf maximal {{ maxImageFileSize/(1024 *1024) }} MB groß sein!<br></span>
            <span v-if="hasImageBackgroundRemovalFailed">Das Bild erfüllt nicht die Transparenz Voraussetzungen.<br>Die Voraussetzungen für die automatische Freistellung des Bildes sind nicht erfüllt.<br> Bitte stellen Sie das Bild manuell frei oder wenden Sie sich an den Customer Success.</span>
          </v-alert>
          <v-alert
            v-else
            type="info"
          >
            Breite: {{ cropWidth }}, Höhe: {{ cropHeight }}
          </v-alert>
        </div>

        <div
          v-if="isRemovingBackground"
          class="d-flex justify-center mt-4 mb-4 align-center"
        >
          <v-progress-circular
            class="mr-2"
            width="3"
            size="20"
            color="primary"
            indeterminate
          />
          Bildhintergrund wird angepasst
        </div>

        <v-checkbox
          v-if="askForImageRights"
          v-model="hasImageRights"
          class="mx-4"
          label="Hiermit bestätige ich, die Rechte an dem bereitgestellten Bild zu besitzen"
        />

        <v-card-actions class="justify-space-between">
          <v-btn
            text
            @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="hasErrors || (askForImageRights && !hasImageRights)"
            @click="saveImage(), (dialog = false)"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import REMOVE_IMAGE_BACKGROUND from '../queries/RemoveImageBackground.gql'

export default {
  components: { VueCropper },
  props: {
    displayImage: {
      type: Object,
      default: () => ({})
    },
    save: {
      type: Function,
      default: () => ({})
    },
    minWidth: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: 12000
    },
    maxHeight: {
      type: Number,
      default: 12000
    },
    accept: {
      type: String,
      default: 'image'
    },
    ratio: {
      type: Number,
      default: 0
    },
    minOpacity: {
      type: Number,
      default: 0
    },
    askForImageRights: {
      type: Boolean,
      default: false
    },
    removeBackground: {
      type: Boolean,
      default: false
    },
    // 12 MB Limit from remove.bg
    maxImageFileSize: {
      type: Number,
      default: 12582912
    }
  },
  data () {
    return {
      mimeType: '',
      file: '',
      image: '',
      dialog: false,
      files: '',
      dialogWidth: undefined,
      cropWidth: 0,
      cropHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      imageOpacity: 0,
      imageFileSize: 0,
      hasImageRights: false,
      isRemovingBackground: false,
      hasRemovedBackground: false
    }
  },
  computed: {
    isImageTooSmall () {
      return this.imageWidth < this.minWidth || this.imageHeight < this.minHeight
    },
    isImageTooBig () {
      return this.imageWidth > this.maxWidth || this.imageHeight > this.maxHeight
    },
    isImageTransparent () {
      return this.imageOpacity >= this.minOpacity
    },
    isImageFileTooBig () {
      return this.imageFileSize > this.maxImageFileSize
    },
    hasErrors () {
      return this.isImageFileTooBig || this.isImageTooSmall || this.isImageTooBig
    },
    hasImageBackgroundRemovalFailed () {
      return this.hasRemovedBackground && !this.isImageTransparent
    },
    canRemoveBackground () {
      return this.removeBackground && !this.hasRemovedBackground && !this.isImageTransparent && !this.hasErrors
    },
    isErrorMessageVisible () {
      return this.hasErrors || this.hasImageBackgroundRemovalFailed
    }
  },
  watch: {
    dialog () {
      this.dialogWidth = undefined
    }
  },
  methods: {
    async removeImageBackground (base64image) {
      this.isRemovingBackground = true

      try {
        const result = await this.$apollo.mutate({
          mutation: REMOVE_IMAGE_BACKGROUND,
          variables: {
            input: {
              base64image
            }
          }
        })
        return result?.data?.removeImageBackground || base64image
      } catch (error) {
        return base64image
      } finally {
        this.isRemovingBackground = false
      }
    },
    onCropmove (event) {
      var data = this.$refs.cropper.getData()
      if (data.width < this.minWidth) {
        event.preventDefault()
        data.width = this.minWidth
        this.$refs.cropper.setData(data)
      }
      if (data.height < this.minHeight) {
        event.preventDefault()
        data.height = this.minHeight
        this.$refs.cropper.setData(data)
      }
      this.ensureImageSize(data)
      this.cropWidth = Math.round(data.width)
      this.cropHeight = Math.round(data.height)
    },
    ensureImageSize (data) {
      if (data.y < 0) {
        data.y = 0
      }
      if (data.height + data.y > this.imageHeight) {
        const offset = (data.height + data.y - this.imageHeight)
        data.y = data.y - offset
        if (data.y < 0) {
          data.y = 0
          data.height = data.height - offset
        }
      }
      this.$refs.cropper.setData(data)
    },
    saveImage () {
      var data = this.$refs.cropper.getData()
      this.ensureImageSize(data)
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit('input', blob)
        this.save(blob)
      }, this.mimeType)
    },
    onUploadClick () {
      this.hasRemovedBackground = false
      this.$refs.FileInput.value = null
      this.$refs.FileInput.click()
    },
    onFileSelect (e) {
      const file = e.target.files[0]
      this.imageFileSize = file.size
      this.mimeType = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.file = event.target.result
          const img = new Image()
          img.onload = async () => {
            const ratio = img.width / img.height
            this.imageOpacity = this.opacityRatio(img)
            this.imageWidth = img.width
            this.imageHeight = img.height
            this.dialogWidth = 600 * ratio
            if (this.canRemoveBackground) {
              this.file = await this.removeImageBackground(event.target.result)
              this.$refs.cropper.replace(this.file)
              // only try removing background once
              this.hasRemovedBackground = true
              img.src = this.file
            }
          }
          img.src = event.target.result
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.file)
          }
        }
        reader.readAsDataURL(file)
      } else {
        alert('Fehler beim Hochladen des Bildes!')
      }
    },
    opacityRatio (image) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      context.drawImage(image, 0, 0)
      const data = context.getImageData(0, 0, canvas.width, canvas.height).data
      let opacity = 0
      for (let i = 0; i < data.length; i += 4) {
        opacity += data[i + 3]
      }
      return 1 - (opacity / 255) / (data.length / 4)
    }
  }
}
</script>
